/*AccountInfo*/
#AccountInfo {
  font-family: 'Source code Pro';
  font-weight: 300;
  color: black;
  opacity: 1;
  text-rendering: optimizeLegibility;
  /*position: fixed;*/
  /*width: 35%;*/
  /*left: 32.5%;*/
  text-align: left;
  font-size: 11px;
  border-radius: 4px;
  /*transition: bottom 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);*/
  background: none;
}

#AccountInfo .header {
  background: #f9f9f9;
  border-radius: 4px 4px 0 0;
  cursor: move;
}
,
#AccountInfo .toolbar,
#AccountInfo .header {
  /*bottom: 100%;*/
  /*width: calc(100% + 2px);*/
  /*border-left: 1px solid #e0e0e0;*/
  /*border-right: 1px solid #dfdfdf;*/
  /*border-top: 1px solid #f2f2f2;*/
  overflow: hidden;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  /* please note that transitions are not supported in IE 9 and there is no -ms prefix */
  transition: background-color 0.3s ease;
}

#AccountInfo .toolbar {
  background: white;
  padding: 8px 3px 0px 8px;
}

#AccountInfo .header {
  padding: 5px 3px 8px 8px;
}

#AccountInfo .info_table {
  background: #fff;
  padding: 8px 5px 8px 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  width: 100%;
  max-height: 200px !important;
  overflow-y: scroll;
}

#AccountInfo .account {
  position: relative;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
  /*border-radius: 5px;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#AccountInfo table {
  table-layout: fixed;
}

#AccountInfo table,
#AccountInfo tr {
  position: relative;
  font-size: 10px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

#AccountInfo td,
#AccountInfo td.txhash {
  padding: 4px 5px 4px 5px;
}

#AccountInfo td.txhash {
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo td.tx_success {
  color: green;
}

#AccountInfo td.tx_failed {
  color: red;
}
#AccountInfo td.tx_unknown {
  color: #ccc;
}

#AccountInfo p {
  font-family: 'Source code Pro';
  margin: 0;
}

#AccountInfo a,
#AccountInfo a:active,
#AccountInfo a:visited {
  text-decoration: none;
  color: black;
}

#AccountInfo a:hover {
  text-decoration: underline;
}

#AccountInfo .tag {
  float: right;
}

#AccountInfo .balance {
  font-family: 'Hack', 'Source Code Pro';
  font-weight: 500;
  font-size: 11px;
}

#AccountInfo .tag,
#AccountInfo .tools a,
#AccountInfo .tools button {
  background: black;
}

#AccountInfo .tag,
#AccountInfo .tools a,
#AccountInfo .tools button {
  box-sizing: border-box;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 6px 2px 6px;
  margin-right: 5px;
  border-radius: 3px;
  display: inline-block;
  border: 1px rgba(80, 80, 80, 0.05) solid;
  border-bottom: 1px rgba(100, 100, 100, 0.06) solid;
  margin-bottom: 4px;
  user-select: none;
  font-family: 'Source code Pro';
  /*text-transform: uppercase;*/
}

#AccountInfo .tools a,
#AccountInfo .tools button {
  background: #eee;
  outline: none;
}

#AccountInfo .tools a:hover,
#AccountInfo .tools button:hover {
  background: #fefefe !important;
  color: #000 !important;
  text-decoration: none;
  border: 1px solid #00ffd9;
  cursor: pointer;
}

#AccountInfo div.address span.hidden {
  display: none;
}

#AccountInfo div.address .name,
#AccountInfo div.address a,
#AccountInfo p.source,
#AccountInfo p.target {
  margin-top: 2px;
  font-size: 10px;
  font-weight: 500;
  background: #eee;
  padding: 1px 5px;
  border-radius: 4px;
  display: inline-block;
}

#AccountInfo div.address .name {
  background: none;
  font-weight: bold;
  color: white;
  font-size: 11px;
}

#AccountInfo p.target {
  margin-left: 5px;
  position: relative;
  right: 0px;
}
#AccountInfo div.address,
#AccountInfo div.sourcetarget {
  margin: auto;
  text-align: center;
}

#AccountInfo div.address a:hover {
  text-decoration: none;
  color: white;
  background: #00ffd9;
}

#AccountInfo .info_table a {
  text-decoration: none;
  color: black;
}

#AccountInfo .info_table a:hover {
  text-decoration: underline;
  color: white;
}

#AccountInfo .info_table .txhash a:hover {
  text-decoration: underline;
  color: black;
}

#AccountInfo .info_table .amount,
#AccountInfo .info_table .denomination {
  font-family: 'Hack';
}

#AccountInfo .info_table .price,
#AccountInfo .info_table .amount {
  text-align: right;
}

.fraction {
  color: #bcbcbc;
  font-weight: 200;
}

#AccountInfo .info_table table {
  border-spacing: 0;
}

#AccountInfo .info_table td {
  border-top: rgba(0, 0, 0, 0.08) 1px dotted;
}

#AccountInfo .info_table tr:first-child td {
  border-top: none;
}

tr.total td.noborder {
  border: none !important;
}

tr.total td:not(.noborder) {
  /*font-weight: 700;*/
  color: black;
  /*border-top: rgba(0, 0, 0, 0.2) 1px solid !important;*/
}

#AccountInfo ._5 {
  width: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo ._10 {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo ._20 {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo ._30 {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo ._40 {
  max-width: 40%;
  min-width: 40%;
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

a.link-scam {
  background-color: red;
}
