@import url('https://fonts.googleapis.com/css?family=Roboto:300|Source+Sans+Pro:300,400|Source+Code+Pro:300,400');
@import url('https://cdn.jsdelivr.net/npm/hack-font@3.3.0/build/web/hack.css');

body {
  font-family: 'Source Sans Pro';
  overflow: hidden;
  height: 100%;
  background: #fafafa;
  margin: 0;
}

body.hovered {
  cursor: pointer;
}

.windowContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% + 200px);
  width: 100%;
}

.shadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 3px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.01);
}

.shadow_big {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1),
    0px 3px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.01);
}

#logo,
#MiniLogo {
  font-family: 'Roboto';
  font-size: 2.4em;
  color: #00ffd9;
  text-align: center;
  font-weight: 300;
}

html body .graph-tooltip {
  background: none;
  font-family: 'Source Code Pro', monospace, monospace;
  font-weight: 300;
  color: black;
  text-align: left;
  opacity: 1;
  text-rendering: optimizeLegibility;
  pointer-events: none;
  user-select: none;
}

html body .graph-tooltip {
  font-weight: 300;
  margin: 0;
  opacity: 1;
  white-space: nowrap;
}

html body .graph-tooltip p {
  margin: 0;
  font-weight: 500;
  white-space: nowrap;
}

html body .graph-tooltip .title {
  font-weight: 500;
  font-size: 12px;
}

html body .graph-tooltip {
  line-height: 1.4em;
  font-size: 9px;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

html body .graph-tooltip .transactions {
  background: #fefefe;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  border-radius: 3px;
  padding: 2px 4px 2px 6px;
}

html body .graph-tooltip .transactions .denomination {
  font-family: 'Hack';
  font-weight: 700;
  text-align: right;
  padding-top: 3px;
}

html body .graph-tooltip .type {
  font-size: 10px;
  font-weight: 500;
  background: black;
  padding: 1px 3px 1px 3px;
  display: inline-block;
  border-radius: 3px;
  margin-top: 2px;
}

html body .graph-tooltip .amount {
  font-weight: 500;
  font-size: 11px;
}

html body .graph-tooltip .transactions .balance {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

#logo:before {
  color: black;
  content: 'ΞTH';
}

#inputbox:hover .guide {
  /*display: block;*/
}

.App {
  text-align: center;
}

body,
#body,
#graph,
#root,
#graphContainer {
  user-select: none;
}

#inputbox input {
  font-weight: 300;
}

#Top.searched {
  /*top: -30px;*/
  top: 40px;
  transition: top 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

#Top #logo {
  opacity: 1;
}
/*
#Top.searched #logo {
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}*/

#Top {
  position: absolute;
  top: 30%;
  margin: auto;
  width: 100%;
  pointer-events: none;
}

#Top #MiniLogo {
  opacity: 0;
  position: fixed;
  left: 10px;
  font-size: 1.1em;
  top: 6px;
}
/*
#MiniLogo:before {
  color: black;
  content: "ΞTH";
  margin-right: 1px;
}

#Top.searched #MiniLogo {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
*/
#Top #address,
#Top input,
#Top div div {
  font-family: 'Source Code Pro', monospace;
  pointer-events: all;
  text-rendering: optimizeLegibility;
}

#Top button:hover {
  background: rgba(255, 255, 255, 0.91);
  color: #00ffd9;
}

#Top a {
  text-decoration: none;
  color: black;
}

#Top a:hover {
  text-decoration: underline;
  color: #00ffd9;
}

#Top a.logo {
  pointer-events: auto;
}

#Top a.logo:hover {
  text-decoration: none;
}

#Top #inputbox {
  width: 470px;
  margin: 6px auto auto auto;
}

#spinner {
  position: absolute;
  margin-left: 4px;
  opacity: 0.5;
}

#toolbar {
  padding-top: 5px;
}

.guide {
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 11px;
  padding-top: 200px;
  letter-spacing: -0.3px;
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.guide a,
.guide b {
  font-weight: 400;
}

.guide .footnote {
  padding-top: 1em;
  font-size: 50%;
}

.guide a {
  text-decoration: underline;
}

.guide.searched {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none !important;
}

#Top input {
  font-size: 13px;
}

input:-internal-autofill-selected,
input:-webkit-autofill {
  background-color: rgba(255, 255, 255, 0) !important;
  background-image: none !important;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px #fafafa;
  /* color: -internal-light-dark-color(black, white) !important; */
}

/* Portrait */
@media only screen and (max-width: 756px) {
  #Top input {
    font-size: 12px;
  }

  #Top #inputbox {
    width: auto;
  }

  #Top {
    width: 80%;
    left: 10%;
  }

  #Top.searched {
    top: 0px;
  }

  #Top.searched #logo {
    display: none;
  }

  #inputbox button span {
    font-size: 10px;
  }
}

.graph-tooltip .balance {
  font-family: 'Hack', 'Source Code Pro', monospace !important;
}
