#donations {
  font-size: 13pt;
  font-family: 'Source Code Pro', monospace;
  position: absolute;
  margin: auto;
  bottom: 10px;
  right: 12px;
}

#donations a,
#donations a:hover {
  text-decoration: none;
  opacity: 0.6;
  margin-left: 5px;
}

@keyframes hvr-pulse-grow {
  to {
    opacity: 1;
  }
}

#donations a:hover,
#donations a:focus,
#donations a:active {
  -webkit-animation-name: hvr-pulse-grow;
  animation-name: hvr-pulse-grow;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

#donations #connectedWallet {
  opacity: 0.5;
}

#donations #connectedWallet .walletText {
  font-size: 9pt;
  font-weight: 300;
  margin-right: 5px;
  opacity: 0.9;
  vertical-align: middle;
  margin-top: -4px;
}

#donations #connectedWallet:hover .walletText,
#donations #connectedWallet:hover {
  opacity: 1;
  cursor: pointer;
  font-weight: 500;
}

#donations div {
  display: inline-block;
}
