@import url(https://fonts.googleapis.com/css?family=Roboto:300|Source+Sans+Pro:300,400|Source+Code+Pro:300,400);
@import url(https://cdn.jsdelivr.net/npm/hack-font@3.3.0/build/web/hack.css);
body {
  font-family: 'Source Sans Pro';
  overflow: hidden;
  height: 100%;
  background: #fafafa;
  margin: 0;
}

body.hovered {
  cursor: pointer;
}

.windowContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% + 200px);
  width: 100%;
}

.shadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 3px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.01);
}

.shadow_big {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1),
    0px 3px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.01);
}

#logo,
#MiniLogo {
  font-family: 'Roboto';
  font-size: 2.4em;
  color: #00ffd9;
  text-align: center;
  font-weight: 300;
}

html body .graph-tooltip {
  background: none;
  font-family: 'Source Code Pro', monospace, monospace;
  font-weight: 300;
  color: black;
  text-align: left;
  opacity: 1;
  text-rendering: optimizeLegibility;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

html body .graph-tooltip {
  font-weight: 300;
  margin: 0;
  opacity: 1;
  white-space: nowrap;
}

html body .graph-tooltip p {
  margin: 0;
  font-weight: 500;
  white-space: nowrap;
}

html body .graph-tooltip .title {
  font-weight: 500;
  font-size: 12px;
}

html body .graph-tooltip {
  line-height: 1.4em;
  font-size: 9px;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

html body .graph-tooltip .transactions {
  background: #fefefe;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  border-radius: 3px;
  padding: 2px 4px 2px 6px;
}

html body .graph-tooltip .transactions .denomination {
  font-family: 'Hack';
  font-weight: 700;
  text-align: right;
  padding-top: 3px;
}

html body .graph-tooltip .type {
  font-size: 10px;
  font-weight: 500;
  background: black;
  padding: 1px 3px 1px 3px;
  display: inline-block;
  border-radius: 3px;
  margin-top: 2px;
}

html body .graph-tooltip .amount {
  font-weight: 500;
  font-size: 11px;
}

html body .graph-tooltip .transactions .balance {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

#logo:before {
  color: black;
  content: '\39ETH';
}

#inputbox:hover .guide {
  /*display: block;*/
}

.App {
  text-align: center;
}

body,
#body,
#graph,
#root,
#graphContainer {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#inputbox input {
  font-weight: 300;
}

#Top.searched {
  /*top: -30px;*/
  top: 40px;
  transition: top 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

#Top #logo {
  opacity: 1;
}
/*
#Top.searched #logo {
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}*/

#Top {
  position: absolute;
  top: 30%;
  margin: auto;
  width: 100%;
  pointer-events: none;
}

#Top #MiniLogo {
  opacity: 0;
  position: fixed;
  left: 10px;
  font-size: 1.1em;
  top: 6px;
}
/*
#MiniLogo:before {
  color: black;
  content: "ΞTH";
  margin-right: 1px;
}

#Top.searched #MiniLogo {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
*/
#Top #address,
#Top input,
#Top div div {
  font-family: 'Source Code Pro', monospace;
  pointer-events: all;
  text-rendering: optimizeLegibility;
}

#Top button:hover {
  background: rgba(255, 255, 255, 0.91);
  color: #00ffd9;
}

#Top a {
  text-decoration: none;
  color: black;
}

#Top a:hover {
  text-decoration: underline;
  color: #00ffd9;
}

#Top a.logo {
  pointer-events: auto;
}

#Top a.logo:hover {
  text-decoration: none;
}

#Top #inputbox {
  width: 470px;
  margin: 6px auto auto auto;
}

#spinner {
  position: absolute;
  margin-left: 4px;
  opacity: 0.5;
}

#toolbar {
  padding-top: 5px;
}

.guide {
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 11px;
  padding-top: 200px;
  letter-spacing: -0.3px;
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.guide a,
.guide b {
  font-weight: 400;
}

.guide .footnote {
  padding-top: 1em;
  font-size: 50%;
}

.guide a {
  text-decoration: underline;
}

.guide.searched {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none !important;
}

#Top input {
  font-size: 13px;
}

input:-internal-autofill-selected,
input:-webkit-autofill {
  background-color: rgba(255, 255, 255, 0) !important;
  background-image: none !important;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px #fafafa;
  /* color: -internal-light-dark-color(black, white) !important; */
}

/* Portrait */
@media only screen and (max-width: 756px) {
  #Top input {
    font-size: 12px;
  }

  #Top #inputbox {
    width: auto;
  }

  #Top {
    width: 80%;
    left: 10%;
  }

  #Top.searched {
    top: 0px;
  }

  #Top.searched #logo {
    display: none;
  }

  #inputbox button span {
    font-size: 10px;
  }
}

.graph-tooltip .balance {
  font-family: 'Hack', 'Source Code Pro', monospace !important;
}

#donations {
  font-size: 13pt;
  font-family: 'Source Code Pro', monospace;
  position: absolute;
  margin: auto;
  bottom: 10px;
  right: 12px;
}

#donations a,
#donations a:hover {
  text-decoration: none;
  opacity: 0.6;
  margin-left: 5px;
}

@-webkit-keyframes hvr-pulse-grow {
  to {
    opacity: 1;
  }
}

@keyframes hvr-pulse-grow {
  to {
    opacity: 1;
  }
}

#donations a:hover,
#donations a:focus,
#donations a:active {
  -webkit-animation-name: hvr-pulse-grow;
  animation-name: hvr-pulse-grow;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

#donations #connectedWallet {
  opacity: 0.5;
}

#donations #connectedWallet .walletText {
  font-size: 9pt;
  font-weight: 300;
  margin-right: 5px;
  opacity: 0.9;
  vertical-align: middle;
  margin-top: -4px;
}

#donations #connectedWallet:hover .walletText,
#donations #connectedWallet:hover {
  opacity: 1;
  cursor: pointer;
  font-weight: 500;
}

#donations div {
  display: inline-block;
}

/*AccountInfo*/
#AccountInfo {
  font-family: 'Source code Pro';
  font-weight: 300;
  color: black;
  opacity: 1;
  text-rendering: optimizeLegibility;
  /*position: fixed;*/
  /*width: 35%;*/
  /*left: 32.5%;*/
  text-align: left;
  font-size: 11px;
  border-radius: 4px;
  /*transition: bottom 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);*/
  background: none;
}

#AccountInfo .header {
  background: #f9f9f9;
  border-radius: 4px 4px 0 0;
  cursor: move;
}
,
#AccountInfo .toolbar,
#AccountInfo .header {
  /*bottom: 100%;*/
  /*width: calc(100% + 2px);*/
  /*border-left: 1px solid #e0e0e0;*/
  /*border-right: 1px solid #dfdfdf;*/
  /*border-top: 1px solid #f2f2f2;*/
  overflow: hidden;
  /* please note that transitions are not supported in IE 9 and there is no -ms prefix */
  transition: background-color 0.3s ease;
}

#AccountInfo .toolbar {
  background: white;
  padding: 8px 3px 0px 8px;
}

#AccountInfo .header {
  padding: 5px 3px 8px 8px;
}

#AccountInfo .info_table {
  background: #fff;
  padding: 8px 5px 8px 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  width: 100%;
  max-height: 200px !important;
  overflow-y: scroll;
}

#AccountInfo .account {
  position: relative;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
  /*border-radius: 5px;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#AccountInfo table {
  table-layout: fixed;
}

#AccountInfo table,
#AccountInfo tr {
  position: relative;
  font-size: 10px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

#AccountInfo td,
#AccountInfo td.txhash {
  padding: 4px 5px 4px 5px;
}

#AccountInfo td.txhash {
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo td.tx_success {
  color: green;
}

#AccountInfo td.tx_failed {
  color: red;
}
#AccountInfo td.tx_unknown {
  color: #ccc;
}

#AccountInfo p {
  font-family: 'Source code Pro';
  margin: 0;
}

#AccountInfo a,
#AccountInfo a:active,
#AccountInfo a:visited {
  text-decoration: none;
  color: black;
}

#AccountInfo a:hover {
  text-decoration: underline;
}

#AccountInfo .tag {
  float: right;
}

#AccountInfo .balance {
  font-family: 'Hack', 'Source Code Pro';
  font-weight: 500;
  font-size: 11px;
}

#AccountInfo .tag,
#AccountInfo .tools a,
#AccountInfo .tools button {
  background: black;
}

#AccountInfo .tag,
#AccountInfo .tools a,
#AccountInfo .tools button {
  box-sizing: border-box;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 6px 2px 6px;
  margin-right: 5px;
  border-radius: 3px;
  display: inline-block;
  border: 1px rgba(80, 80, 80, 0.05) solid;
  border-bottom: 1px rgba(100, 100, 100, 0.06) solid;
  margin-bottom: 4px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: 'Source code Pro';
  /*text-transform: uppercase;*/
}

#AccountInfo .tools a,
#AccountInfo .tools button {
  background: #eee;
  outline: none;
}

#AccountInfo .tools a:hover,
#AccountInfo .tools button:hover {
  background: #fefefe !important;
  color: #000 !important;
  text-decoration: none;
  border: 1px solid #00ffd9;
  cursor: pointer;
}

#AccountInfo div.address span.hidden {
  display: none;
}

#AccountInfo div.address .name,
#AccountInfo div.address a,
#AccountInfo p.source,
#AccountInfo p.target {
  margin-top: 2px;
  font-size: 10px;
  font-weight: 500;
  background: #eee;
  padding: 1px 5px;
  border-radius: 4px;
  display: inline-block;
}

#AccountInfo div.address .name {
  background: none;
  font-weight: bold;
  color: white;
  font-size: 11px;
}

#AccountInfo p.target {
  margin-left: 5px;
  position: relative;
  right: 0px;
}
#AccountInfo div.address,
#AccountInfo div.sourcetarget {
  margin: auto;
  text-align: center;
}

#AccountInfo div.address a:hover {
  text-decoration: none;
  color: white;
  background: #00ffd9;
}

#AccountInfo .info_table a {
  text-decoration: none;
  color: black;
}

#AccountInfo .info_table a:hover {
  text-decoration: underline;
  color: white;
}

#AccountInfo .info_table .txhash a:hover {
  text-decoration: underline;
  color: black;
}

#AccountInfo .info_table .amount,
#AccountInfo .info_table .denomination {
  font-family: 'Hack';
}

#AccountInfo .info_table .price,
#AccountInfo .info_table .amount {
  text-align: right;
}

.fraction {
  color: #bcbcbc;
  font-weight: 200;
}

#AccountInfo .info_table table {
  border-spacing: 0;
}

#AccountInfo .info_table td {
  border-top: rgba(0, 0, 0, 0.08) 1px dotted;
}

#AccountInfo .info_table tr:first-child td {
  border-top: none;
}

tr.total td.noborder {
  border: none !important;
}

tr.total td:not(.noborder) {
  /*font-weight: 700;*/
  color: black;
  /*border-top: rgba(0, 0, 0, 0.2) 1px solid !important;*/
}

#AccountInfo ._5 {
  width: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo ._10 {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo ._20 {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo ._30 {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#AccountInfo ._40 {
  max-width: 40%;
  min-width: 40%;
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

a.link-scam {
  background-color: red;
}

